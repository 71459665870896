/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,600;1,700;1,800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
 

body {
  background-color: #F3F4FB !important;
  margin: 0;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.loaction-box h2 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 0 0 15px 0; 
  text-transform: capitalize;
}

.form-check.form-check-inline {
  /*! background-color: #7c6fdb; */
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  color: #7e7c7c;
  align-items: center;
  margin: 4px 0px;
  border-radius: 30px;
  border: 1px solid #7e7c7c;
  font-weight: bold;
}
.active .form-check-label{
color: #fff;
}

.form-check-label {
  display: block;
  width: 100%;
  height: 100%;
  padding: 7px 0;
  font-weight: 500;
  color: #000;
}

input {
  padding: 10px 10px;
  -webkit-text-size-adjust: none; 
}
.location-check .form-check .form-check-input{
  margin-top: 0.25em;
}
.loaction-box.card {
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: unset;
}

.loaction-box.card2 {
  background: #f1ab17;
}
.loaction-box {
  width: 50%;
}

.loaction-box .card {
  width: 50%;
}

.location-check {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 20px 10px;
  margin: 13px 12px;
}

.loaction-box h2 span {
  background-color: #b644fe;
  border-radius: 40px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0px 0;
  color: #fff;
}

.location-inner {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 20px 10px;
  margin: 13px 12px;
}
.loaction-box .location-inner  h2{
  margin-bottom: 0;
}
/* .form-check-input:checked {
  background-color: #ED9C28 !important;
  border-color: #ED9C28 !important;
} */
input[type="checkbox"] {
  /* Hide the default checkbox */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* Set the dimensions of the custom checkbox */
  width: 20px;
  height: 20px;
  /* Set the border and background of the custom checkbox */
  border: 1px solid #aaa;
  background-color: #fff;
  /* Set the checked icon */
  background-image: url('./images/tick.png');
  background-repeat: no-repeat;
  background-position: center center;
}
.form-check-input:checked[type=radio]{
  --bs-form-check-bg-image: url('./images/tick.png') !important;
}
.form-check-input:checked {
  background-color:#fff !important;
    border-color: #fff !important;
}

.active {
  color: #fff !important;
  /* background-image: linear-gradient(rgb(8, 58, 102), rgb(0, 39, 72)); */
  background-image: linear-gradient(100deg, #b644fe 1.7%, #f2effa 97.1%);

}
.calendar-box-main{
  display: flex;
}
.caledar-box {
  width: 85px;
  text-align: center;
  /* border: 1px solid #012648 */
  border: 1px solid #b446ff;
  background: #fff;
}

.caledar-box span {
  color: #fff;
  background-color: #b446ff;
  /* background-color: #012648; */
  width: 100%;
  display: block;
}

.caledar-box p {
  font-size: 16px;
  color: #2d68e8 !important;
  /* color: #012648 !important; */
  margin: 0 0 4px 0 !important;
  text-transform: uppercase;
}

.caledar-box p strong {
  color: #333;
  display: block;
  font-size: 25px;
}

.location-check-data p {
  margin: 0;
  color: #858383;
}

.location-check-data button {
  color: #fff;
  background-color: #b446ff;
  /* background-color: #ED9C28; */
  padding: 10px 30px;
  border-radius: 100px;
  border: unset;
}

.location-check-data {
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
}

.location-check-data:last-child {
  border-bottom: none;
}
/* .call-to-action {
  padding: 100px;
  background-size: 100%;
} */

header {
  background: url(./images/header-bg.webp) no-repeat;
  background-position: center;
  background-size: 100%;

/*   
  background-color: #353538; */
  /* background: linear-gradient(rgb(8, 58, 102), rgb(0, 39, 72)); */
  padding: 10px 0;
}

.section-one h1 {
  text-align: center;
  font-weight: 900 !important;
  font-size: 25px !important;
  padding: 0;
  line-height: 1;
}
.top-heading{
  padding: 0 20px;
}
.section-one {
  margin: 30px 0;
}
.section-one .container{
  padding: 0;
}

section.section-one p {
  font-size: 20px;
  text-align: center;
  margin: 20px 0  ;
  font-weight: 400;
  
}


section.section-one .calendar-title p {
  text-align: left;
  margin-bottom: 0;
  margin-top: 0;
  color: #000;
}

.calendar-title h5 {
  margin-bottom: 0;
}

.locations-box {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: baseline;
}
.p-0.ps-1.calendar-title {
  width: 65%;
}
@media only screen and (min-width: 1024px) {
  iframe{
  height: 500px !important;
  }
}


@media only screen and (max-width: 1024px) {
  .locations-box {
    flex-direction: column;
  }

  .loaction-box.card {
    width: 100%;
  }

  .choose-us {
    padding: 30px !important;
    /* padding: 70px 30px !important; */
  }

  .benifits {
    gap: 30px;
    flex-direction: column;
  }

  .inner-benifit {

    margin: 0 auto;
  }

  .call-to-action {
    padding: 30px !important;
    background-size: cover !important;
  }

  .call-to-action h1 {
    margin: 20px auto !important;
    padding: 25px 15px !important;
    font-size: 28px !important;
    line-height: 1.5;
  }

  .about-us img {
    width: 100%;
  }

  .about-us {
    padding:20px 30px 0px !important;
  }

  .about-bottom {
    flex-direction: column;
  }

  .about-bottom .about-bottom-sec {
    width: 100% !important;
    padding: 0 !important;
  }
  .socail-links .socail-links-inner{
    gap: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .location-check-data button {
    padding: 5px 21px;
    font-size: 14px;
  }



}






@media only screen and (max-width: 500px) {
  header {
    text-align: center;
  }

  h1 {
    font-size: 1.4rem !important;
    font-weight: bold !important;
    padding: 0 60px;
  }

  .col-6.p-0.ps-3.calendar-title {
    padding-left: 28px !important;
  }

  /* .caledar-box {
    width: 70px;
  } */

  .calendar-title h5 {
    font-size: 16px;
    font-weight: 600;
    padding-right: 5px;
  }



}

.logo {
  text-align: center;
}

.logo img {
  width: 265px;
  background: linear-gradient(45deg, black, #C89116);
}
.loading {
  text-align: center;

}

.loading img {
  width: 90px;
  margin: 70px 0;
}

.choose-us {
  background-color: #264272;
  color: #fff;
  padding: 30px;
  text-align: center;

}

.choose-us h2 {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 20px;
}

.choose-us ul {
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
  font-size: 20px;
}

.choose-us ul strong {
  color: #eeb43f;
  font-weight: 700;
}

.choose-us ul p {
  margin-bottom: 1px;
}

.choose-us .choose-us-investors {
  max-width: 1100px;
  margin: 30px auto;
}

.choose-us .choose-us-investors h2 {
  color: #eeb43f;
  line-height: 1.3em;
  font-size: 32px;
  margin-bottom: 10px;
}
.choose-us .choose-us-investors p{
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 20px;
}

.benifits {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 60px auto 0;

}

.benifits img {
  width: 80px;
}

.benifits h3 {
  font-size: 23px;
  font-weight: 500;
  margin: 20px;
}

.benifits p {
  font-size: 20px;
}


.inner-benifit {
  max-width: 350px;
}


.call-to-action {
  background: url(./images/bg3.webp) no-repeat;
  padding: 100px;
  background-size: 100%;
  background-position: center;
}

.call-to-action h1 {
  padding: 25px 5px;
  background-color: #00000080;
  max-width: 1200px;
  margin: 80px auto !important;
  color: #fff;
  font-size: 32px;
}

.call-to-action h1 strong {
  color: #eeb43f;
  line-height: 1.3em;
  font-size: 28px;
}


.take-action {
  background-color: #eeb43f;
  padding: 80px;

}

.take-action h4 {
  max-width: 1200px;
  margin: 0 auto;
  font-size: 32px;
  font-weight: 700;
  text-align: center;

}

footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 30px;
}

.footer-inner {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-inner p {
  margin-bottom: 5px;
  font-size: 14px;
}

.about-us {
  background-color: #264272;
  color: #fff;
  padding: 0px 0 30px;
  text-align: center;
}

.about-us-inner {
  max-width: 1200px;
  margin: 0 auto;
}

.about-us .about-top h2 {
  color: #eeb43f;
  font-weight: 700;
  margin-bottom: 30px;
}
.about-us-btn{
  margin: 30px auto 0!important;
}
.about-us button,.about-us-btn {
  background-color: #eeb43f;
  color: #264272;
  width: 216px;
  text-decoration: none;
  padding: 15px 20px;
  font-weight: 700;
  border-color: #eeb43f;
  border-width: 1px;
  border-style: solid;
  letter-spacing: 0;
  border-radius: 50px;
  font-size: 20px;
  text-shadow: 0 0 0 transparent;
  text-transform: uppercase;
  margin: 30px auto;
}

.about-mid {
  margin-top: 30px;
}

.about-mid h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
}

.about-mid p {
  text-align: left;
font-size: 20px;
}

.about-bottom {
  background-color: #eeb43f;
  display: flex;
  padding: 20px;
  align-items: center;
  color: #000;
}

/* .about-bottom .img-section {
  width: 50%;
} */

.about-bottom img {
  border: 5px solid #fff;
  width: 100%;
  float: left;
  max-width: 330px;
  margin-right: 14px;
  margin-bottom: 6px;
}

.about-bottom h3 {
  color: #264272;
  font-weight: 700;
  font-size: 32px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.about-bottom .text-section {
  /* width: 50%; */
  /* padding: 0 30px; */
  text-align: left;
}

.about-bottom p {
  text-align: left;
  font-size: 20px;
}

 




.confirm-btn-clss {
  display: none !important;
}

.heading-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
}

.heading-modal img {
  width: 17px;
  cursor: pointer;
}
.sweet_alert h2{
  display: none;
}
.heading-modal h4{
  font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 15px 0 15px 0;
}
.modal-timing {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  padding: 0 20px;
}
sup {
  color: red;
  font-size: 14px;
  top: unset;
  font-weight: 500;
}
.modal-timing h3 {
  font-size: 14px;
  color: #004AAD;
  margin-right: 20px;
  margin-bottom: 0;
}

.modal-timing label {
  display: flex;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}

.modal-timing label input {
  margin-right: 5px;
}
.modal-form{
  background-color: #FFFFFF;
  /* border: 1px dashed #CDE0EC;
  border-radius: 4px; */
  width: 100%;
  /* border-color: #CDE0EC; */
  padding: 0 20px;
  /* box-shadow: 0px 0px 0px 0px #FFFFFF; */
}
.modal-form label{
  display: flex;
    flex-direction: column;
    text-align: left; 
    font-size: 14px;
    font-weight: 400;
    color: #000;
}
.thank-you-form .submit-form{
margin-top: 10px;
}
.modal-form  .submit-form button {
  background-color: #B446FF;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 10px 0;
  border: unset;
  width: 100%;
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  font-size: 19px;
  
}

.modal-form input {
  background-color: #F7FAFC ;
  color: #000000 ;
  border: 1px solid #ccc;
  border-radius: 5px;    
  padding: 10px;
  box-shadow: 0px 0px 0px 0px #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0.5rem;
  background-clip: inherit ;
}
.submit-form{
  text-align: left;
}
.error {
  color: #cb3837;
}

.page-cointaner{
  max-width: 1200px;
  margin: 0 auto;
}

.thank-you-page{
  background: white;
}
.thank-header {
  padding: 30px 0;
}
.header-inner{
  display: flex;
  align-items: center;
  padding: 30px 0;

}
.thank-header img{
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.thank-header h1{
  text-align: left;
}
.thank-header p{
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 0;
  color: #353335;
  font-weight: 500;
}
.congraultion{
  /* background: url(./images//bg4.jpeg) no-repeat; */
  background: url(./images/header-bg.webp) no-repeat;
  position: relative;
  background-size: 100%;
  background-position: center;
}

.congraulation-inner {
  padding: 20px 10px;
  background-color: rgba(0, 0, 0, 0.3);
}
 



.congraultion h2{
  color: #fee516; 
    font-weight: 400;
    /* padding: 0 40px; */
    opacity: 1;
    text-shadow: 0 0 0 transparent;
    line-height: 1;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 0;
}

.congraultion p{
  font-size: 23px;
  /* padding: 0 40px; */
    text-shadow: 0 0 0 transparent; 
    color: #fff; 
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 0;
}
.next-step{
  padding: 40px;
}
.nextstep {
  padding: 20px;
  background-color: #f8f2f0;
}
.nextstep h2{
  font-weight: 700;
  font-size: 32px;
}
.nextstep p{
  color: #000000;
  font-weight: 400;
  padding: 10px 0;
  text-shadow: 0 0 0 transparent;
  line-height: 1.3em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  font-size: 16px;
  margin-bottom: 0; 
}

.addtocalender{
  background-color: #f8f2f0;
  margin-top: 20px;
}


.thank-you-page .thank-header {
  padding: 0px 0 0;
}

.thank-header h1 { 
padding: 20px 20px 10px;
color: #465878;
}

.thank-you-page  .header-inner {
display: flex;
align-items: center;
padding: 14px 20px 0;
}

.thank-you-page  .next-step {
padding: 20px;
}

.thank-you-page .nextstep h2 {
font-weight: 400;
font-size: 22px;
}
form.modal-form.thank-you-form {
background: unset;
padding: 0;
}

form.modal-form.thank-you-form label {
font-weight: 500;
}

form.modal-form.thank-you-form label input {
margin-top: 0;
margin-bottom: 6px;
border: 1px solid #85807d;
padding: 7px;
}

form.modal-form.thank-you-form input[type="submit"] {
background: #be660e;
width: 100%;
padding: 13px 0;
}

.thank-you-page h6 {
font-weight: unset;
font-size: 14px;
margin: 10px 0 0;
}
.next-step h2 {
font-weight: unset;
font-size: 22px;
}
.add-to-calender {
text-align: center;
}
.add-to-calender a{
background: #2777ec;
color: #fff;
padding: 12px 0;
text-align: center;
text-decoration: none;
display: block;
width: 70%;
margin: 0 auto;
} 



















.thank-footer{
  background-color: #264272;
  color: #fff;
  text-align: center;
  padding: 70px 30px 50px;
}
.thank-footer img{
  width: 200px;
}


.sweet-alert.sweet_alert{
  padding: 0 !important;
}
.color-white{
  color: #fff;
}
.congration_guest span{
font-size: 16px;
}
.choose-us-second{
  padding-top: 27px;
}
.socail-links {
  padding: 30px 0 20px;
  text-align: center;
}
.socail-links-inner {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  gap: 50px;
  margin-top: 30px;
}
.socail-links-inner img {
  width:60px;
}